:global .font-body-global {
  // font-size  : 0.8em;
  // line-height: 1.2;
  // font-weight: 200;
  // font-family: "monaco";
}

:global .font-title-global {
  font-family   : Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  font-weight   : 100;
  font-size     : 15px;
  color         : $orange;
}

:global .font-feature-global {
  font-family   : 'bebas';
  color         : $highlight;
  letter-spacing: 4px;
}

.font-body {
  font-size  : 0.8em; // line-height: 1.2;
  font-weight: 100;
}

.font-title {
  letter-spacing: 3px;
  font-weight   : 100;
  color         : $orange;
}

.font-feature {
  font-family   : 'bebas';
  color         : $highlight;
  letter-spacing: 4px;
}