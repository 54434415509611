@import "../../sass/colours";

.panel {
  border          : 1px solid #d4d4d4;
  width           : 100%;
  padding         : 20px;
  background-color: #f1f1f1;
  margin          : 10px 0;
}

.topRow {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  color          : #8a8a8a;
}

.topRight {
  text-transform: uppercase;
}

.preview {
  font-size     : 1.3rem;
  text-transform: capitalize;
  cursor        : pointer;
}

.detailToggle {
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  color          : $highlight;
  cursor         : pointer;
}

.detail {
  margin-top: 5px;
}