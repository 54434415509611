$dark                     : #222;
$light                    : #fff;
$highlight                : #2fa2b8;
$highlight-faded          : #73a5af;
$gray                     : #8a8a8a;
$orange                   : #ce7d23;
$purple                   : #dc31ae;
$highlight2               : $orange;
$lightgray                : #e6e6e6;
$validateTrue             : #48ab5e;
$validateFalse            : #d65353;
$grey                     : $gray;
$lightgrey                : $lightgray;
$gradientBackground       : linear-gradient($highlight,darken($highlight,10%));
$gradientBackgroundGray   : linear-gradient($lightgray,darken($lightgray,10%));
$gradientBackgroundWarning: linear-gradient($orange,darken($orange,10%));
$gradientBackgroundDanger : linear-gradient($validateFalse,darken($validateFalse,10%));
$gradientBackgroundSuccess: linear-gradient($validateTrue,darken($validateTrue,10%));
$spacing                  : 4px;
$tilePadding              : $spacing;