.mainfooter {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  color: white;
  background-color: black;
  padding: 3px 10px;
  z-index: 10;
  font-size: 0.5rem;
}

.mainfooter-item-dim {
  color: #5d6175;
}

.mainfooter-logo {
  height: 38px;
  padding: 5px;
}

.mainfooter-spacer {
  padding-left: 5px;
  padding-right: 5px;
  color: #5d6175;
}