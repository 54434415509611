// :global {
//   @import "react-select/dist/react-select.min.css";
// }
@import "colours";
@import "fonts";
@font-face {
  font-family: bebas;
  src        : url("../css/fonts/bebas_neue/BebasNeue Regular.otf");
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:600');

:global html {
  background-color: black;
  font-size       : 11pt;
}

:global body {
  margin               : 0;
  padding              : 0 5px 70px;
  min-height           : 100vh;
  // min-width            : 100vw;
  background-image     : url("../img/bg1.jpg");
  background-size      : cover;
  background-position  : center;
  background-attachment: fixed;
  background-color     : #3482b1;
  background-blend-mode: multiply;
  line-height          : 1.3;
  ////
  @extend .font-body-global;
}
@media(min-width:767px) {
  :global body {
    padding: 25px 30px 70px;
  }
}

:global .app {
  background-color: rgba(255, 255, 255, 1);
  /* background-color: rgba(47, 162, 184, 0.95); */
  /* margin: 50px; */
  border-radius   : 0 0 20px 20px;
  max-width       : 1200px;
  margin          : auto;
}
// @media(min-width:767px) {
  // :global .app {
    // min-width: 1100px;
  // }
// }

:global a {
  color: #2fa2b8;
}

:global a:hover {
  color: #aac4ca;
}

:global b {
  font-weight: 700;
}

:global h1 {
  font-family: 'Montserrat';
}

:global h3 {
  font-family: 'Montserrat';
  color      : $grey;
}

:global h4 {
  text-transform: uppercase;
  color         : $gray;
  letter-spacing: -1px;
  border-bottom : 1px solid $lightgrey;
  font-size     : 17px;
}

:global h5 {
  border-bottom : 1px solid $lightgrey;
  padding-bottom: 5px;
  margin        : 0 0 $spacing;
  text-transform: uppercase;
  @extend .font-title-global;
}

:global h6 {
  border-bottom: 1px solid $lightgrey;
  // padding-bottom: 5px;
  // margin       : 20px 0 0;
  font-weight: bold;
  @extend .font-feature-global;
}

:global .btn-sm {
  font-size     : 0.6rem;
  vertical-align: inherit;
}

:global .btn-primary {
  background  : $light;
  color       : $highlight;
  border-color: $highlight;
}

:global .btn-primary:hover {
  background-color: $highlight;
  border-color    : $highlight;
}

:global .btn-info {
  background: $gradientBackground;
}

:global .btn-warning {
  background: $gradientBackgroundWarning;
}

:global .btn-danger {
  background: $gradientBackgroundDanger;
}

:global .btn-success {
  background: $gradientBackgroundSuccess;
}

:global .dataview {
  padding: 3px;
  width  : 100%;
}

:global .applet {
  padding            : 4px;
  display            : flex;
  flex-direction     : row;
  background-size    : cover;
  background-position: center;
  border-radius      : 0 0 20px 20px;
}
@media(min-width:767px) {
  :global .applet {
    padding: 10px;
  }
}

:global .btn-refresh {
  float: right;
}

:global .footer-logo {
  max-width: 150px;
  width    : 80%;
  margin   : 20px auto;
}

:global .footer-logo img {
  width: 100%;
}

:global .err-notify {
  text-align : center;
  font-weight: bold;
  font-size  : 24pt;
}