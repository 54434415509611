@import "../../sass/colours";
@import "../../sass/fonts";

.section {
  padding        : 8px;
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  justify-content: center;
}
.wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: 'wrap';
  justify-content: center;
  align-items: center;
  border        : 1px solid #c7c7c7;
  margin          : 10px 0;
  padding         : 20px;
  width           : 100%;
}
.col{
  display: flex;
  flex-direction: column;
  flex-wrap: 'wrap';
  justify-content: center;
  align-items: center;
}
.info{
  padding:5px 0;
  max-width:300px;
}
.qrcode {
  // width:100%;
  max-width: 100%;
  margin:4px;
  border:1px solid black;
}


.downloadButton{
margin:5px;
background-color:$highlight;
color:$light;
border-radius:5px;
padding:5px;
font-size:1.5rem;
font-weight:bold;
text-align: center;
cursor:pointer;
display:none;
}

@media (min-width:767px){
  .wrapper{
    width : 47%;
    margin: 10px ;
    flex-direction: row;
  }
  .qrcode{
    max-width:150px;
    max-height:150px;
  }
  .downloadButton{
    font-size:1rem;
    display:block;

  }
}