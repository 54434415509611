@import "../../sass/colours";
@import "../../sass/fonts";


.wrapper {
  padding: 10px;
}

.warning {
  // font-weight: 900;
  font-size : 12px;
  font-style: italic;
  color     : #e05252;
}
.bookingId{
  font-size: 0.7rem;
}
.artistwarningWrapper{
  padding:0px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.artistwarning {
  font-size : 13px;
  // font-weight: 900;
  font-style: italic;
  color     : #e05252;
}

.artistwarningButtonWrapper{
  margin:10px 0;
}
.artistwarningButton{
  font-size : 13px;
  color     : #e05252;
  border-radius:4px;
  padding:4px;
  border:1px solid #e05252;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 300ms;
}
.artistwarningButton:hover{
  background-color:#dec3dd;
}

.confirmed {
  font-size : 12px;
  font-style: italic;
  color     : green;
}

.section {
  padding        : 8px;
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  justify-content: center;
}

.detailCheck {
  font-size : 0.8rem;
  color     : #e05252;
  font-style: italic;
  // font-weight: 900;
}

.column {
  border          : 1px solid #d4d4d4;
  width           : 100%;
  padding         : 20px;
  background-color: #f1f1f1;
  margin          : 10px 0;
}
@media(min-width:767px) {
  .column {
    width : 47%;
    margin: 10px;
  }
}

.column-map {
  @extend .column;
  padding: 0;
}

.halfWidth {
  width: 100%;
}
@media(min-width:767px) {
  .halfWidth {
    width : 47%;
    margin: 10px;
  }
}

.section_header {
  font-family    : 'Montserrat';
  font-size      : 1.3rem;
  text-transform : uppercase;
  color          : $orange;
  cursor         : pointer;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
}
@media(min-width:767px) {
  .section_header {
    font-size: 1.6rem;
  }
}
// .section_header_title{}

.section_header_description{
  font-size      : 0.7rem;
  color:$grey;
  text-transform: none;
}

.artistName {
  font-size  : 20px;
  font-weight: bold;
  color      : $highlight;
}

.title {
  font-size: 40px;
  color    : $highlight;
}
//
// .title_artists {
//   font-family    : 'Montserrat';
//   font-size      : 1.6rem;
//   text-transform : uppercase;
//   color          : $orange;
//   cursor         : pointer;
//   display        : flex;
//   flex-direction : row;
//   justify-content: space-between;
// }

.booking-id {
  color          : $grey;
  font-size      : 10px;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
}

.locationFrame {
  max-width: 100%;
  width    : 100%;
  height   : 100%;
  // border   : 1px solid $gray;
  // margin   : 5px 0;
}

.toggleButton {
  margin    : 0 8px;
  text-align: right;
}

.toggleButton span {
  color: $lightgray;
}

.info-item {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  width          : 100%;
  max-width      : 100%;
  border-bottom  : 1px solid #e8e8e8;
  font-size      : 1.0rem;
}
@media(min-width:767px) {
  .info-item {
    font-size: 1.4rem;
  }
}

.key {
  margin-right: 8px;
  color       : $grey;
}

.value {
  text-align: right;
}

.contactButton {
  padding         : 5px;
  width           : fit-content;
  background-color: #2fa2b8;
  border-radius   : 5px;
  color           : white;
  font-size       : 8pt;
  text-transform  : uppercase;
}

.pickupWrapper {
  @extend .column;
}

.profilePicture {
  width: 100px;
}

.flexRow {
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  justify-content: space-between;
}

.blue {
  color: $highlight;
}

.link {
  color : $highlight;
  cursor: pointer;
}

.driverWrapper {
  display       : flex;
  flex-direction: row;
  border        : 1px solid #c7c7c7;
  margin        : 5px 0;
}

.driverhead {
  color     : $highlight;
  font-style: italic;
  font-size : 1.6rem;
}

.driverName {
  text-transform: capitalize;
}

.driverPic {
  border: 1px solid $gray;
  height: fit-content;
}

.driverInfo {
  padding: 10px;
}

.contactWrapper {
  display       : flex;
  flex-direction: row;
  border        : 1px solid #c7c7c7;
  margin        : 5px 0;
}

.vehicle {
  text-transform: capitalize;
}

.transportBrief {
  font-weight: bold;
}

.contacthead {
  color     : $highlight;
  font-style: italic;
  font-size : 1.6rem;
}

.contactPic {
  display: none;
}
@media(min-width:767px) {
  .contactPic {
    display: block;
  }
}

.contactInfo {
  padding  : 0 10px;
  font-size: 1.5rem;
}

.contactName {
  color    : $highlight;
  font-size: 2rem;
}

.contactEmail {
  color    : $highlight;
  font-size: 1.0rem;
}

.briefing {
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  justify-content: space-around;
}

.briefing > div {
  border          : 1px solid #d4d4d4;
  width           : 100%;
  padding         : 20px;
  background-color: #f1f1f1;
  margin          : 10px 0;
}
@media(min-width:767px) {
  .briefing > div {
    width: 47%;
  }
}

.recordWarning{
  font-size: 0.6rem;;
}

.passLinks{
  display: flex;
  flex-direction: column;
  font-weight: bold;
}